import React from 'react'
import { IconContext } from "react-icons";
import { FaApple } from "react-icons/fa";
import { FaAndroid } from "react-icons/fa";
const Downloads = () => {
    return (
        <>
            <a href="https://apps.apple.com/kg/app/uram/id1574174505">
                <IconContext.Provider value={{ size: '2.65rem' }}>
                    <FaApple />
                </IconContext.Provider>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.rental_technology.uram">
                <IconContext.Provider value={{ size: '2.65rem' }}>
                    <FaAndroid />
                </IconContext.Provider></a>
        </>
    )
}

export default Downloads