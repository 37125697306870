import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
const Cities = () => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    const cities = [{
        name: "Ташкент"
    },

    { name: "Бухара" },
    {
        name: "Ухта"
    }, {
        name: "Нефтекамск"
    }]
    return (
        <div className='App__cities wrapper'>
            <div className="App__cities ">
                <h1 className='text-center super-bold h1 mb-30'>Локации</h1>
             
                <div className="slider-container">
                    <div className="slider">
                        <div className="App__cities_cards slides">
                            <div id='slides_1' className='App__cities_card slide'>
                                <img src="https://cdn2.tu-tu.ru/image/pagetree_node_data/1/8b1351257581047a46a963bceb018b3e/" alt="Ташкент" />
                                <a class="slide__prev" href="#slides__4" title="Next"></a>
                                <a class="slide__next" href="#slides__2" title="Next"></a>
                                <p className='h5'>Ташкент</p>
                            </div>
                            <div id='slides_2' className='App__cities_card'>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/2012_Bukhara_7515821196.jpg/1200px-2012_Bukhara_7515821196.jpg" alt="Бухара" />
                                <a class="slide__prev" href="#slides__1" title="Next"></a>
                                <a class="slide__next" href="#slides__3" title="Next"></a>
                                <p className='h5'>Бухара</p>
                            </div>
                            <div id='slides_3' className='App__cities_card'>
                                <img src="https://im.kommersant.ru/Issues.photo/CORP/2017/11/15/KMO_128025_03259_1_t218_203157.jpg" alt="Ухта" />
                                <a class="slide__prev" href="#slides__2" title="Next"></a>
                                <a class="slide__next" href="#slides__4" title="Next"></a>
                                <p className='h5'>Ухта</p>
                            </div>
                            <div id='slides_4' className='App__cities_card'>
                                <img src="https://ic.pics.livejournal.com/zdorovs/16627846/1902648/1902648_original.jpg" alt="Нефтекамск" />
                                <a class="slide__prev" href="#slides__1" title="Next"></a>
                                <a class="slide__next" href="#slides__3" title="Next"></a>
                                <p className='h5'>Нефтекамск</p>
                            </div>

                        </div>
                    </div>
                </div>
                <h3 className='App__cities_name text-center super-bold h3 mb-30'>{cities[index].name}</h3>
                <Carousel activeIndex={index} onSelect={handleSelect} className='App__cities_slider' >
                    <Carousel.Item>
                        <img src="https://cdn2.tu-tu.ru/image/pagetree_node_data/1/8b1351257581047a46a963bceb018b3e/" alt="Ташкент" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/2012_Bukhara_7515821196.jpg/1200px-2012_Bukhara_7515821196.jpg" alt="Бухара" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="https://im.kommersant.ru/Issues.photo/CORP/2017/11/15/KMO_128025_03259_1_t218_203157.jpg" alt="Ухта" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="https://ic.pics.livejournal.com/zdorovs/16627846/1902648/1902648_original.jpg" alt="Нефтекамск" />
                    </Carousel.Item>
                </Carousel>
                
            </div>
        </div>
    )
}

export default Cities