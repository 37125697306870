import React, { useState } from 'react';
import scooter from "../assets/images/scooter.png"
const Scooter = () => {
  const [currentScooterInfo, setCurrentScooterInfo] = useState(0)
  const scooterInfo = [{
    name: "Надежный и безопасный",
    id: 0,
    description: `Никаких открытых кабелей и тросов.
    Барабанные тормоза работают совместно с электронным тормозом обеспечивая наиболее комфортное и плавное торможение.
    Двойная подножка обеспечивает лучшую устойчивость самоката при стоянке.`
  }, {
    name: "Электронный замок",
    id: 1,
    description: `Передача информации о столкновениях,
    запасной канал связи через Bluetooth,
    обновление прошивки IOT по "воздуху",
    мониторинг состояния самоката онлайн и возможность удаленного управления функциями самоката,
    регистрация всех событий самоката в память IOT, (черный ящик)
    система защиты самоката от угона и перепрошивки,
    работает в режимах 2G, 3G, 4G,
    возможно определение местоположения с точностью до 0.5 метра`
  }, {
    name: "Продвинутый IOT (интернет вещей)",
    id: 2,
    description: "Замок батарейного отсека с электронным управлением повышает безопасность и сохранность аккумулятора."
  }]
  return (
    <div className="App__scooter mt-50">
      <h1 className='display-5 super-bold text-center'>OKAI ES400</h1>
      <div className="App__scooter wrapper">

        <div className='App__scooter flex justify-center column'>
          <h4 className='h4'>OKAI ES400 - Модель наших самокатов, которые славятся своей надежностью и безопасностью</h4>
          <h5 className='h5'>Преимущества, которые мы хотим подчеркнуть:</h5>
          <div className="App__scooter_content flex justify-center"> <div className='App__scooter_sub-block flex mt-20 gap-50'>
            <ol className='list flex gap-30 column'>{scooterInfo.map((scooter, index) => (
              <li onClick={() => setCurrentScooterInfo(scooter.id)} className='cursor'><span className={`list-number ${scooter.id === currentScooterInfo && "selected"}`}>{scooter.id + 1}</span>{scooter.name}</li>
            ))}
            </ol>
            <p className='App__scooter_description'>{scooterInfo[currentScooterInfo].description}</p>
          </div>

            <div className='App__scooter_image'>
              <img src={scooter} alt="E-scooter" width={800} height={800} />
            </div></div>
        </div>
      </div>
    </div>
  )
}

export default Scooter