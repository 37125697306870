import React from 'react'

const TutorialCard = ({tutorial}) => {

    return (
        <>
            {tutorial.map((tutorial, index) => (
                <div key={index} className="card-block">
                    <div className="background">
                        <img width={428} height={300} src={tutorial.image} alt="Tutorial" />
                    </div>
                    <div className='flex align-center card-block__text'>
                        <h1 className='h5'>{tutorial.title}</h1>
                        <p>{tutorial.description}</p>
                    </div>
                </div>
            ))}</>
    )
}

export default TutorialCard