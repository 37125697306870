import React, { useRef } from 'react'
import video from "../assets/video/video.mp4"

const Video = () => {
  const videoRef = useRef()
  const setPlay = () => {
    videoRef.current.playbackRate = 0.5;
  }
  return (
    <div className='App__video'>
      <video onCanPlay={() => setPlay()} ref={videoRef} preload="auto" id='video' loop="loop" muted="muted" autoPlay="autoplay" className="video">
        <source src={video} type="video/mp4" />
      </video>
    </div>
  )
}

export default Video