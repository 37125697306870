import React from 'react'
import Faq from "react-faq-component";
export const Help = ({ help }) => {
  const data = {
    title: "Помощь",
    rows: [
      {
        title: "Со скольки лет можно пользоваться сервисом?",
        content: "Пользоваться сервисом можно с 16 лет."
      }, {
        title: "Нужны ли какие-то проверки данных перед использованием сервиса?",
        content: "Пользоваться можно уже сразу после привязки банковской карты."
      },
      {
        title: "Есть ли какие-то ограничения по весу для катания на самокате?",
        content: "Да. Максимально допустимый вес - 100 кг."
      }, {
        title: "Куда поставить самокат после заверешения использования?",
        content: "Самокат необходимо будет вернуть в стойку проката. Вы несете ответственность пропажу самоката."
      }, {
        title: "Что делать, если я нанес повреждения самокату?",
        content: "Необходимо обратиться в службу технической поддержки"
      },]

  }
  const styles = {
    transitionDuration: ".2s",
    timingFunc: "ease",
    rowContentPaddingBottom: '10px',
    rowContentPaddingLeft: '30px',
    rowContentPaddingRight: '10px'
  };
  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
  };
  return (
    <div className='App__faq content-block'>
      <h1 ref={help} className="App__faq_heading h1 super-bold">Помощь</h1>
      <p className='h5 text-center mb-20'>Здесь вы найдете ответы на свои вопросы.</p>
      <div className="App__faq_content">
        <Faq
          data={data}
          styles={styles}
          config={config}
        />
      </div>
    </div>
  )
}

