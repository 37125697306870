import React from 'react'

const OfferCard = ({ offers, size }) => {
    console.log(offers)
    return (
        <>
            {offers.map((offer, index) => (
                <div key={index} className="App__advantages_content mini-block content-block">
                    <div className="App__davantages_icon"><offer.icon value={{size:'2.65rem'}} /></div>
                    <h3 className="App__advantages_heading h3 super-bold">{offer.title}</h3>
                    <p className="App__advantages_text h6">{offer.description}</p>
                </div>
            ))}
        </>


    )
}

export default OfferCard