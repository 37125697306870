import React from 'react'
import image from "../assets/images/greeting.jpg"

const Greeting = () => {
    return (
        <div className='App__greeting container content-block'>
            <div className="App__greeting_content">
                <div className="App__greting_text-block"><h2 className='App__greeting_heading h2 super-bold'>URAM - Шеринг самокатов №1 в Узбекистане</h2>
                    <p className="App__greeting_sub-text">
                        Мы поможем вам сделать ваше путешествие по городу комфортным.
                    </p></div>
                <div className="App__greeting_image-block"><img width={536} height={360} src={image} alt="Man with e-scooter" /></div>
            </div>
        </div>
    )
}

export default Greeting