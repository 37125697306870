import React from 'react';
import { FaMountainCity } from "react-icons/fa6";
import { RiSmartphoneFill } from "react-icons/ri";
import { IoPricetagsSharp } from "react-icons/io5";
import OfferCard from '../components/OfferCard';
const Advantages = () => {
    const offers = [{
        title: "Ташкент и Бухара",
        description: 'Насладитесь красотами Ташкента и Бухары, проезжая на наших самокатах.',
        icon: FaMountainCity
    },
    {
        title: 'Приложение',
        description: 'Всего за пару кликов, вы сможете найти самокат и реализовывать ваши мечты, катаясь по всему городу.',
        icon: RiSmartphoneFill
    },
    {
        title: "Тарифы",
        description: "Мы предлагаем тарифы по демократичной цене, у нас несколько тарифов, которые подойдут под ваши разные нужды.",
        icon: IoPricetagsSharp
    }
    ]
    return (
        <div className='App__advantages'>
            <div className="App__advantages__wrapper ">
                <OfferCard offers={offers} />
            </div>
        </div>
    )
}

export default Advantages