import React, { useRef } from 'react'
import logo from "../assets/images/logo.svg"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ProgressBar from "react-scroll-progress-bar";
const HeaderPage = ({ help, feedback, tariffs, trip, scooter,cities }
) => {
  const startRef = useRef(null)
  const scrollStart = () => (
    startRef.current?.scrollIntoView({ behavior: 'smooth' })
  )
  return (
    <div ref={startRef} >
      <Navbar collapseOnSelect  fixed='top' expand="lg" className="bg-body-tertiary mb-30 padding-none">
        <ProgressBar bgcolor="#000" height="6px" duration="0.2" />
        <Container className="App__header align-center">
          <Navbar.Brand href="/"><img width={60} height={60} src={logo} className="App-logo" alt="Logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className='align-center justify-between adaptive-flex-bar' id="responsive-navbar-nav">
            <Nav className="me-auto App__header_list adaptive-flex-list flex">
              <Nav.Link onClick={scrollStart}> <p className="h5">Главная</p> </Nav.Link>
              <Nav.Link onClick={scooter}> <p className="h5">ES400</p> </Nav.Link>              
              <Nav.Link onClick={trip}><p className='h5'>Поездка</p></Nav.Link>
              <Nav.Link onClick={cities}><p className='h5'>Города</p></Nav.Link>              
              <Nav.Link onClick={tariffs}> <p className="h5">Тарифы</p> </Nav.Link>
              <Nav.Link onClick={help}> <p className="h5">Помощь</p> </Nav.Link>
              <Nav.Link onClick={feedback}> <p className="h5">Связаться с нами</p> </Nav.Link>    
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  )
}

export default HeaderPage