import React from 'react'
import logo from "../assets/images/favicon.svg"
import TutorialCard from "../components/TutorialCard"
import Downloads from '../components/Downloads'
const Demonstration = () => {
    const tutorial = [{
        title: "Найдите",
        description: 'Найдите самокат на карте в приложении URAM',
        image: '/images/phone_1.png'
    }, {
        title: "Просканируйте",
        description: 'Далее отсканируйте QR код, чтобы разблокировать самокат.',
        image: "/images/phone_2.png"
    }, {
        title: "Поездка",
        description: 'Следуйте ПДД и наслаждайтесь поездкой',
        image: '/images/phone_3.jpg'
    }]
    return (
        <div className='App__demonstration'>
            <div className="App__demonstration_content">
                <div className="App__demonstration_show">
                    <h3 className="App__demonstration_heading super-bold display-6  h3">Как начать поездку</h3>
                    <div className="App__demonstration_list">
                    <TutorialCard tutorial={tutorial} />
                    </div>
                    <p className="App__demonstration_text h2 super-bold">Еще нету нашего приложения?</p>
                    <div className="App__demonstration_buttons">
                        <div className="button-group flex align-center">
                            <div className="button-group_logo">
                                <img width={62} height={50} src={logo} alt="Logo" />
                            </div>
                            <div className="button-group_text">
                                <h1 className='h3 super-bold'>URAM</h1>
                                <p>Приложение для iOS и Android</p>
                            </div>
                            <div className="button-group_buttons flex align-center">
                               <Downloads/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Demonstration