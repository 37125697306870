import { useState, useEffect, useRef } from "react";
import Advantages from "./view/Advantages";
import Demonstration from "./view/Demonstration";
import Greeting from "./view/Greeting";
import HeaderPage from "./view/HeaderPage"
import Rules from "./view/Rules";
import Footer from "./view/Footer";
import "./styles/animations.scss"
import { Help } from "./view/Help";
import Logo from "./view/Logo";
import Video from "./view/Video"
import Tariffs from "./view/Tariffs";
import Scooter from "./view/Scooter";
import Cities from "./view/Cities";
function App() {
  const rulesRef = useRef(null)
  const helpRef = useRef(null)
  const feedbackRef = useRef(null)
  const tariffRef = useRef(null)
  const tripRef = useRef(null)
  const scooterRef = useRef(null)
  const citiesRef = useRef(null)
  const [animate, setAnimate] = useState(false)
  const [show, setShow] = useState(false)
  const [move, setMove] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 2000);
    setTimeout(() => {
      setMove(true)
    }, 2400);
  }, [])
  useEffect(() => {
    document.documentElement.classList.add('lenis-scrolling')
  })
  useEffect(() => {
    if (animate === false) {
      document.body.classList.remove('body-after')
      document.body.classList.add('body-before');
    }
    else {
      setTimeout(() => {
        document.body.classList.add('body-after')
        document.body.classList.remove('body-before')
        setShow(true)
      }, 200);
    }
  }, [animate])
  const scrollRules = () => (
    rulesRef.current?.scrollIntoView({ behavior: 'smooth' })
  )
  const scrollFeedback = () => (
    feedbackRef.current?.scrollIntoView({ behavior: 'smooth' })
  )
  const scrollHelp = () => (
    helpRef.current?.scrollIntoView({ behavior: 'smooth' })
  )
  const scrollTariffs = () => (
    tariffRef.current?.scrollIntoView({ behavior: 'smooth' })
  )
  const scrollTrip = () => (
    tripRef.current?.scrollIntoView({ behavior: 'smooth' })
  )
  const scrollScooter = () => (
    scooterRef.current?.scrollIntoView({ behavior: 'smooth' })
  )
  const scrollCities = () => (
    citiesRef.current?.scrollIntoView({ behavior: 'smooth' })
  )
  return (
    <>
      <div id="#home" className="App">
        {animate ? (<div style={show ? { opacity: '1' } : { opacity: '0' }} >
          <HeaderPage
            help={scrollHelp} feedback={scrollFeedback}
            rules={scrollRules} tariffs={scrollTariffs}
            trip={scrollTrip} scooter={scrollScooter}
            cities={scrollCities} />
          <div className="App__container">
            <div className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Greeting /></div>
            <div className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Advantages /></div>
            <div ref={scooterRef} className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Scooter /></div>
          </div>
          <div className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Video /></div>
          <div ref={tripRef} className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Demonstration /></div>
          <div ref={citiesRef} className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Cities /></div>
          {/* <div ref={tariffRef} className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Tariffs /></div> */}
          <div ref={rulesRef} className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Rules /></div>
          <div className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Help help={helpRef} /></div>
          <div ref={feedbackRef} className={`animate-elem ${move ? 'show-elem' : 'hide-elem'}`}><Footer /></div>
        </div>
        ) : <Logo />}
      </div>
    </>
  );
}

export default App;
