import * as React from "react"
import { motion } from "framer-motion"
const Logo = () => {
    const [hide, setHide] = React.useState(false);
    React.useEffect(() => {
        setTimeout(() => {
            setHide(true)
        }, 1800);
    }, [])

    return (
        <div className="logo-bg">
            <svg style={hide === false ? { opacity: '1' } : { opacity: '0' }}
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                viewBox="0 0 1024 820" height={'800px'}
            >
                <motion.g
                    fill={"#ffffff"} id="1"
                    animate={{ y: [3500, 0, 0] }}
                    transition={{
                        duration: 3,
                        type: 'keyframes',
                        ease: 'easeInOut'
                    }}
                >
                    <path d="M0 0v476.406C0 523.31 19.012 565.762 49.75 596.5s73.19 49.75 120.094 49.75h684.312c46.904 0 89.356-19.012 120.094-49.75 30.74-30.738 49.75-73.19 49.75-120.094V0H914v476.406c0 16.188-6.308 31.089-17.531 42.313-11.224 11.224-26.125 17.531-42.313 17.531H169.844c-16.188 0-31.089-6.307-42.313-17.531C116.307 507.495 110 492.594 110 476.406V0M27 710v110h970V710H27Z" />
                </motion.g>
            </svg>
        </div>
    )
}
export default Logo
