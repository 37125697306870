import React from 'react'
const Rules = () => {



  return (
    <div className='App__rules content-block'>
      <div className="App__rules_content container">
        <div className="App__rules">
          <h3 className="App__rules_heading super-bold display-6 h3 text-center">Правила дорожного движения</h3>
          <p className="App__rules_text h5 text-center">С 1 марта 2023 года электросамокаты получили официальный статус в ПДД – теперь это средство индивидуальной мобильности. </p>
          <div className='flex pdd-cards'>
            <div className="pdd-card">
              <img width={132} height={132} src="https://thumb.tildacdn.com/tild3035-3330-4737-a662-393663366334/-/resize/158x/-/format/webp/1.png" alt="Sign 3.35" />
              <p>Знак 3.35 Движение на средствах индивидуальной мобильности запрещено.</p>
            </div>
            <div className="pdd-card">
              <img width={260} height={132} src="https://thumb.tildacdn.com/tild3762-3765-4661-b631-333930313237/-/resize/266x/-/format/webp/image2023_upscayl_8x.jpg" alt="Sign 8.4.7.2" />
              <p>Знак 8.4.7.2 Вид транспортного средства.</p>
            </div>
            <div className="pdd-card">
              <img width={190} height={132} src="https://thumb.tildacdn.com/tild3137-6239-4032-b330-646466316461/-/resize/193x/-/format/webp/image2022_upscayl_8x.jpg" alt="Sign 8.4.16" />
              <p>Знак 8.4.16 Кроме вида транспортного средства.</p>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rules